import React from 'react';
// import Scene from './Scene';
import Game from './game/game';
import './styles/app.css';

function App() {
  return (
    <div>
      <h1> </h1>
      <Game />
      {/* <Scene /> */}
    </div>
  );
}



export default App;
